import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RoomDtoModel } from '@heitown/common-dto';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class RoomApiClient extends BaseCRUDApiClient<RoomDtoModel> {
  baseUrl = this.config.apiEndpoint + '/room';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }
}
