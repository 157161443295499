import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AllotmentDtoModel } from '@heitown/common-dto';
import { FilterOperator, FilterType } from '@heitown/common-interfaces';

import { ApiClientConfig } from '../api-client-config';
import { BaseCRUDApiClient } from './baseCRUD.api-client';

@Injectable()
export class AllotmentApiClient extends BaseCRUDApiClient<AllotmentDtoModel> {
  baseUrl = this.config.apiEndpoint + '/allotment';

  constructor(http: HttpClient, config: ApiClientConfig) {
    super(http, config);
  }

  getDailyOccupancy(hotelId: string, from: string, to: string) {
    return this.http.post<
      Array<{
        day: string;
        reservedActiveRoomsCount: number;
        totalActiveRoomsCount: number;
      }>
    >(`${this.baseUrl}/daily-occupancy`, {
      hotelId: hotelId,
      from: from,
      to: to,
    });
  }

  getAllotments(hotelId: string, dateFrom: string, dateTo: string) {
    return this.getPaged(9999, 0, {}, [
      {
        field: 'date',
        operator: FilterOperator.inRange,
        type: FilterType.date,
        value: [dateFrom, dateTo],
      },
      {
        field: 'hotelId',
        operator: FilterOperator.equals,
        type: FilterType.text,
        value: [hotelId],
      },
    ]);
  }

  getAvailability(
    hotelId: string,
    startDate: string,
    guestNumber: number,
    roomNumber: number
  ) {
    return this.http.post<{ date: string; isAvailable: boolean }[]>(
      this.baseUrl + '/availability',
      {
        hotelId,
        guestNumber,
        roomNumber,
        startDate,
      },
      {
        headers: {
          'x-api-key': '81d078be-161a-4adc-92e5-ded8615045a2',
        },
      }
    );
  }

  getDayAvailability(
    hotelId: string,
    startDate: string,
    guestNumber: number,
    roomNumber: number
  ) {
    return this.http.post<{ date: string; isAvailable: boolean }[]>(
      this.baseUrl + '/day-availability',
      {
        hotelId,
        guestNumber,
        roomNumber,
        startDate,
      },
      {
        headers: {
          'x-api-key': '81d078be-161a-4adc-92e5-ded8615045a2',
        },
      }
    );
  }

  getDailyGuestNumber(hotelId: string, startDate: string, endDate: string) {
    return this.http.post<{ day: string; guestNumber: number }[]>(
      this.baseUrl + '/daily-guest-number',
      {
        hotelId: hotelId,
        from: startDate,
        to: endDate,
      }
      // {
      //   headers: {
      //     'x-api-key': '81d078be-161a-4adc-92e5-ded8615045a2',
      //   },
      // }
    );
  }
}
